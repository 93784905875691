.button {
	width: auto;
	height: auto;
	border: none;
	background-color:#C64231;
	color:#F2F3F0;
	font-weight:600;
	transition: all .3s;
	outline: none;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding:9px 30px 11px 11px;
	border-radius: 5px;
	font-size:20px;
	position:relative;
	text-decoration:none;
	border:2px solid #C64231;
	cursor:pointer;

	&:after{
		position:absolute;
		content:'';
		background-image:url("../img/icon/arrow-right-white.svg");
		background-repeat:no-repeat;
		background-size:100% 100%;
		background-position:center center;
		width:10px;
		height:10px;
		top:0;
		bottom:-1px;
		margin:auto 0;
		right:8px;
	}

	&:hover{
		background-color:#FFF;
		color:#C64231 !important;

		&:after{
			background-image:url("../img/icon/arrow-right-red.svg");
		}
	}
	&:visited{
		color:#F2F3F0;
	}
}

.buttons{
	.button{
		&:last-of-type{
			background-color: #FFF;
			color: #0B2B3A;
			border: 2px solid #0B2B3A;
			margin-left:15px;

			&:hover{
				color:#F2F3F0 !important;
				background-color:#0b2B3a;

				&:after{
					background-image:url("../img/icon/arrow-right-white.svg");
				}
			}

			&:after{
				background-image: url("../img/icon/arrow-right-blue.svg");
			}
		}
	}
}

form{
	.button{
		padding: 15px 30px 15px 20px;
		line-height: 24px;

		&:hover{
			padding: 15px 30px 15px 20px;
		}
	}
}