@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Source+Sans+Pro:400,600');

// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-semi:           600;
$weight-bold: 			700;

// Font settings
$base-font-family: 		'Source Sans Pro', sans-serif;
$base-font-weight: 		$weight-regular;
$base-font-size: 		18px;
$base-font-color1: 		#8C9599;
$base-font-color2: 		#FFFFFF;

// Headings
$heading-h1-size:		50px;
$heading-h2-size:		37px;
$heading-h3-size:		24px;
$heading-h4-size:		16px;
$heading-h5-size:		14px;
$heading-h6-size:		11px;

$heading-font-family:	'Libre Baskerville', serif;
$heading-font-weight: 	$weight-regular;
$heading-font-color1:	#0B2B3A;
$heading-font-color2:	#333;

h1,h2,h3,h4,h5,h6{
  margin:0;
}

ul{
  padding: 0;
  margin: 0;
}