// Imports
@import "general";

.flex{
  @include flex-wrap(wrap);
  @include flexbox();

  @include breakpoint(mobile){
    display:block;
  }

  &:before,&:after{
    display:none;
  }
}
// top navigation bar
header.main-navigation {
  position: fixed;
  height: 100px;
  background-color: #FFF;
  z-index: 999;
  /* float: left; */
  /* display: inline-block; */
  width: 100%;
  left: 0;
  top: 0;

  @include breakpoint(mobile){
    height:60px;
  }

  @include breakpoint(mobile){
    .col-lg-12.custom-col.navigation{
      padding:9px 15px;
      display: inline-block;
      width:100%;
    }
  }

  .navigation {
    padding: 25px 15px;

    @include breakpoint(mobile){
      padding:12px 15px;

    }

    .logo {
      display: inline-block;
      float: left;

     img{
       max-width:135px;
       width:100%;
       @include breakpoint(mobile){
         width:80%;
       }
     }
    }

    nav {
      display: inline-block;
      float: left;
      margin-left: 35px;

      @include breakpoint(tablet-landscape) {
        margin-left: 25px;
      }

      ul {
        line-height: 42px;


        li {
          display: inline-block;
          font-size: 16px;
          float: left;
          margin-left: 20px;
          line-height:51px;

          @include breakpoint(tablet-landscape) {
            font-size: 15px;
          }

          @include breakpoint(mobile) {
            line-height: 35px;
          }

          &:first-of-type {
            margin-left: 0;
          }

          a {
            color: #0B2B3A;
            display:inline-block;
            text-decoration: none;
            padding-bottom: 6px;
            position: relative;
            line-height:1;

            &:after {
              content: '';
              background-color: #C64231;
              width: 0;
              height: 2px;
              position: absolute;
              transition: width .3s;
              bottom: 0;
              left: 0;
            }

            @media  only screen and (min-width:1025px){
              &:hover {
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }

        .active {
          position: relative;
          padding-bottom: 6px;

          &:after {
            content: '';
            background-color: #C64231;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;

            @include breakpoint(mobile){
              display:none;
            }
          }

          @include breakpoint(mobile){
            color:#C64231;

          }
        }
      }

      .buttons{
        padding:25px 20px;
        margin-left:0;


        .button {
          margin-top:25px;
          line-height:1;
          float:left;

          &:nth-child(2) {
            float: right;
            margin-left:0;
          }
        }
      }
    }

    .buttons {
     float:right;

      @include breakpoint(tablet-landscape){
        float:none;
        display:inline-block;
        margin-left:130px;
      }

      @include breakpoint(tablet){
        margin-left:55px;
      }

      .button {
        font-size: 16px;

        @include breakpoint(tablet-landscape) {
          font-size: 14px;
        }
      }
    }
  }

    // hamburger menu styling
  .top-button {

    display: none;
    @include breakpoint(tablet-landscape) {
      z-index: 2;
      position: relative;
      border-radius: 4px;
      @include flexbox();
      align-items:center;
      width: 80px;
      float: right;

      .hamburger {
        margin-left: 8px;
        width: 20px;
        height: 15px;
        transition: 0.3s ease;
        display: inline-block;

        .line {
          height: 3px;
          width: 20px;
          display: block;
          background-color: #C64231;
          transition: 0.3s ease;
          position: absolute;

          &:nth-child(1) {
            transform: translateY(0px);
          }

          &:nth-child(2) {
            transform: translateY(6px);

          }

          &:nth-child(3) {
            transform: translateY(12px);
          }
        }
      }

      .is-active {
        transform: translateY(5px);

        .line {
          &:nth-child(1) {
            transform: rotate(-45deg) translateY(0) !important;
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(45deg) translateY(0);
          }
        }
      }

      span {
        height: 40px;
        width: 55px;
        text-align: center;
        line-height: 40px;
        display: inline-block;
        color: #0B2B3A;
        border-radius: 0px;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }

  //hamburger menu
  .header-navigation{
    @include breakpoint(tablet-landscape){
      position:fixed;
      width:100%;
      background-color:#FFF;
      z-index:9999;
      top:100px;
      right:-100%;
      height:100%;
      opacity:0;
      @include transition(all .3s);
      visibility:hidden;


      li{
        width:100%;
        font-size:20px !important;
        margin-left:0 !important;
        padding:25px 20px;
        border-bottom:1px solid #dbdbdb;
      }
    }

    @include breakpoint(mobile){
      top:60px;
      overflow-y:scroll;
      padding-bottom:100px;
    }
  }

  .active{
    opacity:1;
    right:0;
    visibility: visible;
  }
}

//header with h1
.header{
  background-color:#FFF;
  padding-top:105px;
  padding-bottom:120px;

  @include breakpoint(tablet){
    padding-top:20px;
    padding-bottom:50px;
  }

  h1{
    font-weight:$weight-bold;
    line-height:60px;
    margin-bottom:20px;

    @include breakpoint(mobile){
      font-size:34px;
      line-height:45px;
    }
  }

  p{
    margin-top:0;
    font-size:24px;
    line-height:37px;

    @include breakpoint(mobile){
      font-size:20px;
    }

    &:last-of-type{
      margin-bottom:0 !important;
    }
  }

  .button{
    padding:15px 30px 15px 20px;
    line-height:1;

    &:after{
      right:10px;
    }
  }


  ._image{
    text-align:right;

    @include breakpoint(tablet){
      text-align:center;
    }

    img {
      margin-top: -50px;
      margin-right:125px;

      @include breakpoint(tablet-landscape){
        margin-right:105px;
      }

      @include breakpoint(tablet){
        margin-top:30px;
        margin-right:0;
      }

      @include breakpoint(mobile){
        height: auto;
        width: 60%;
        margin-top: 30px;
      }
    }
  }

  //home page
  &._home{
    position:relative;

    ._image{
      text-align:left;

      img{
        position:absolute;
        top:-45px;
        margin-top:0;
        margin-right:0;

        @include breakpoint(tablet-landscape){
          top:-80px;
        }

        @include breakpoint(tablet){
          max-width: 45%;
          height: auto;
          right: 0;
          top: -155px;
        }

        @include breakpoint(mobile){
          max-width: 80%;
          top: -300px;
          width: 80%;
          height: auto;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }
    }


    p{
      margin-bottom:50px !important;

      @include breakpoint(mobile){
        margin-bottom:0 !important;
        &:last-of-type {
          margin-top: 260px;
        }
      }
    }

    .button{
      &:first-of-type {
        @include breakpoint(mobile) {
          margin-bottom: 15px;
          margin-right: 15px;
          float: left;
        }
      }
      &:last-of-type{
        background-color: #FFF;
        color: #0B2B3A;
        border: 2px solid #0B2B3A;
        margin-left:15px;
        //padding:14px 26px 13px 19px;

        &:hover{
          color:#F2F3F0 !important;
          background-color:#0b2B3a;

          &:after{
            background-image:url("../img/icon/arrow-right-white.svg");
          }
        }

        &:after{
          background-image: url("../img/icon/arrow-right-blue.svg");
        }
      }

      @include breakpoint(mobile){
        &:last-of-type{
          float:left;
          margin-left:0;
        }

        font-size:16px;
      }
    }
  }

  //news detail page header
  &._news{
    img{
      box-shadow:10px 10px 0px 0px #C64231;
      height:auto;

      @include breakpoint(mobile){
        width:100% !important;
      }
    }
  }

  //contact page header
  &._contact{
    p {
      margin-bottom:35px;
      @include breakpoint(mobile){
        margin-bottom:0;
      }
      &:last-of-type{
        margin-top:45px;
        margin-bottom:20px !important;

        @include breakpoint(mobile){
          margin:15px 0px;
        }
      }
      a {
        color: #C64231;
        text-decoration:none;
      }
    }

    .button{
      @include breakpoint(mobile){
        float:none !important;
        margin-bottom:15px;
      }
    }

    .maps-iframe{
      padding-left:50px;

      @include breakpoint(tablet-landscape){

        padding-left:15px;
      }

      @include breakpoint(tablet){
        margin-top:20px;
      }

      p{
        margin:0;

        @include breakpoint(mobile){
          text-align:center;
        }

        iframe{
          box-shadow:10px 10px 0px 0px #C64231;

          @include breakpoint(tablet){
            width:100%;
            //height:auto;
          }
        }
      }
    }
  }
}

// general sections
section{
  padding-top:105px;
  padding-bottom:110px;

  @include breakpoint(mobile){
    padding-top:50px;
    padding-bottom:50px;
  }
}

.gray-section{
  background-color:$base-color1;

  &._news{
    p{
      &:first-of-type{
        margin-bottom:50px;
      }
    }
  }

  h2{
    line-height:44px;

  }

  .volunteers{
    h2{
      margin-bottom:30px;
    }
    p{
      line-height:28px;
      margin-bottom:35px;

      @include breakpoint(mobile){
        margin-bottom:20px;
      }

      &:last-of-type{
        margin-bottom:0;

        @include breakpoint(mobile){
          margin-bottom:35px;
        }
      }
    }
  }

  .volunteer-team{

    @include breakpoint (mobile){
      text-align:center;
    }
    img{
      box-shadow:10px 10px 0px 0px #C64231;
      margin-left:100px;

      @include breakpoint(tablet){
        margin-left:0;
      }
    }
  }

  .calender{
    iframe {
     max-width:520px;
      padding:15px;
      background-color: #FFF;
      @include border-radius(10px);
      float:right;
      height:573px;


      @include breakpoint(tablet){
        max-width:100%;
      }

      @include breakpoint(mobile){
        height:400px;
      }
    }
  }
}

.white-section{
  padding:0;

  h2{
    @include breakpoint(mobile){
      font-size:30px;
    }
  }

  p{
    margin-bottom:25px;
  }
}

.red-section{
  background-color:#C64231;
  padding-bottom:120px;
  @include breakpoint(tablet){
    padding-top:70px;
    padding-bottom:70px;
  }

  h2{
    color:#F2F3F0;
    margin-bottom:20px;
    line-height:44px;
  }

  .p-wrapper {

    @include breakpoint(tablet-and-above){
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;

      -webkit-column-gap: 80px; /* Chrome, Safari, Opera */
      -moz-column-gap: 80px; /* Firefox */
      column-gap: 80px;
    }

    p {
      color:#F2F3F0;
      line-height: 28px;
      margin-top:0;
      margin-bottom:20px;
      text-align:justify;

      &:last-of-type{
        margin-bottom:0;
      }
    }
  }

  .button{
    background-color: #FFF;
    color: #C64231;
    border: 2px solid #C64231;

    &:hover{
      color:#FFF !important;
      background-color:#C64231;
      border:2px solid #FFF;

      &:after{
        background-image:url("../img/icon/arrow-right-white.svg");
      }
    }

    &:after{
      background-image: url("../img/icon/arrow-right-red.svg");
    }
  }

}

// gallery section news page
.gallery{
  padding-bottom:0;
}


//news item on homepage
.news-homepage {
  padding:0;

  p{
    margin:0;
  }

  .col-md-12{
    padding:0;
  }

  .visual {
    background-repeat:no-repeat;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size:cover;
    background-attachment: scroll;
    background-position:top center;
    padding:110px 0px 110px 0px;

    @include breakpoint(mobile){
      padding-top:50px;
      padding-bottom:50px;
    }

    .block {
      background-color: #FFF;
      padding: 38px 36px;
      border-radius: 5px;

      h2 {
        font-size: 30px;
        line-height: 36px;
      }

      p {
        line-height: 28px;
        margin:20px 0px;
      }
    }

  }
}

// footer and copyright banner
.logo-section{

._logo{
  text-align:center;
}


  .col-xs-6{
    @include breakpoint(mobile){
      &:nth-child(1), &:nth-child(2){
        margin-bottom:15px;
      }
    }
  }

}

footer{
  background-color:rgba(140, 149, 153, 0.1);
  padding-top:70px;
  padding-bottom:90px;
  @include breakpoint(mobile){
    padding-top:50px;

    .row{
      margin: 0 -7.5px;
      display: flex;
      flex-wrap: wrap;

      &:before, &:after{
        display:none;
      }
    }

    .col-sm-6{
      padding: 0 7.5px;

      &:nth-child(1), &:nth-child(3){
        margin-bottom:20px;
      }
    }
  }

  h4{
    line-height:19px;
    margin-bottom:14px;
  }

  p{
    line-height:26px;
    font-size:16px;
    margin-bottom:20px;
  }

  .button{
    font-size:16px;

    &:hover{
      text-decoration:none;
      color:#F2F3F0;
    }
  }

  address{
    line-height:26px;
    font-size:16px;
    font-style:normal;

    .footer-tel{
      margin-top:25px;
    }


  }
  a{
    color:#C64231;
    display:block;
    text-decoration:none;

    &:hover{
      color:#0B2B3A;
      text-decoration:none;
    }
  }

  ul{
    li{
      display:block;
      line-height:26px;
      font-size:16px;
      a{
        color:$base-font-color1;

      }
    }
  }

  .footer-logo{
    position:relative;
    height:189px;
    width:100%;

    @include breakpoint(mobile){
      height:auto;
    }

    img{
      position:absolute;
      bottom:0;
      width:100%;

      @include breakpoint(mobile){
        width:60%;
        max-width:213px;
        top:0;
      }
    }
  }
}

.copyright{
  background-color:#0B2B3A;
  padding:40px 15px;

  @include breakpoint(mobile){
    padding:40px 0px;
  }

  p{
    display:inline-block;
    float:left;
    margin:0;
    font-size:14px;
    line-height:14px;

    @include breakpoint(mobile){
      font-size:13px;
    }

    &:first-of-type{
      border-right:1px solid #8C9599;
      padding-right:10px;
      font-weight:$weight-semi;
    }

    &:nth-child(2){
      margin-left:10px;
    }

    &:last-of-type{
      float:right;

      @include breakpoint(mobile){
        float:none;
      }
    }

    a{
      text-decoration:none;

      &:hover{
        text-decoration:underline;
        color:#8C9599;
      }
    }
  }
}

// global nesting and classes for the courses.
// H2 title for the courses.
.course-title{
  h2{
    margin-bottom:40px;

    @include breakpoint(tablet-landscape){
      margin-bottom:25px;

    }

    @include breakpoint(mobile){
      font-size:28px;
      margin-bottom:20px;
    }
  }

  p{
    margin:0;
  }

  a{
    float:right;
    line-height:44px;

    @include breakpoint(mobile){
      float:none;
    }
  }
}

// red links in the course blocks
.course-link{
  color:#C64231 !important;
  position:relative;
  padding-right:20px;
  text-decoration:none;
  font-weight:$weight-semi;

  &:hover{
    text-decoration:underline
  }

  &:after{
    position:absolute;
    content:'';
    background-image:url("../img/icon/arrow-right-red.svg");
    background-repeat:no-repeat;
    background-size:100% 100%;
    background-position:center center;
    width:10px;
    height:10px;
    top:0;
    bottom:0;
    margin:auto 0;
    right:0;
  }
}

// the course containers and content styling
.item-container {
  margin-left: -30px;
  margin-right: -30px;

  @include breakpoint(mobile){
    margin-left:-15px;
    margin-right:-15px;
  }

  .item-col{
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom:60px;

    @include breakpoint(tablet){
      padding-left:15px;
      padding-right:15px;
    }


    .inner{
      display: inline-block;
      position:relative;
      width: 100%;
      height: 100%;
      background-color: #FFF;
      float: left;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
      @include border-radius(5px);

      @include breakpoint(mobile){
        margin-bottom:15px;
      }

      header {
        position: relative;
        display: inline-block;
        width: 100%;
        float: left;

        figure {
          margin: 0;

          img{
            float:left;
            @include border-top-radius(5px);
            width:100%;
          }
        }

        // red block in the course images
        time {
          position: absolute;
          background-color: #C64231;
          bottom: 22px;
          left: 22px;
          padding: 2px 7px;
          font-size: 16px;
          font-weight: 600;
          color: #F2F3F0;
        }
      }

      // content container course blocks
      main {
        padding: 36px 29px 97px 36px;
        float: left;
        width:100%;

        @include breakpoint(mobile){
          padding: 25px 20px 70px 20px;
        }

        h2{
          font-size:30px;
          line-height:36px;

          @include breakpoint(tablet-landscape){
            font-size:26px;
          }
        }

        p{
          line-height:28px;

          &:last-of-type{
            margin-bottom:0;
          }
        }
      }

      // placement of the red links
      .item-footer{
        bottom:47px;
        position:absolute;

        @include breakpoint(mobile){
          bottom: 30px;
        }
      }
    }

    .news-items{
      h2{
        margin-top:10px;
        font-size:19px !important;
        line-height:24px !important;
        margin-bottom:20px;
      }
      time{
        color:#C64231;
        text-transform:uppercase;
        font-size:16px;
      }

      p{
        font-size:13px;
        margin:0;
      }
    }
  }
}

// styling for board member blocks
.board-container{
  padding-bottom:95px;

  @include breakpoint(mobile){
    padding-bottom:0;
  }
  p{
    margin-bottom:35px !important;

    &:last-of-type{
      margin-bottom:50px;
    }
  }

  h2{
    margin-bottom:10px;
    @include breakpoint(mobile){
      font-size:30px;
    }
  }
}

.img-left{
  img{
    @include border-left-radius(5px);

    @include breakpoint(mobile){
      @include border-left-radius(0px);
      @include border-top-radius(5px);
    }
  }
  .content.news-content{
    @include border-right-radius(5px);

    @include breakpoint(mobile){
      @include border-right-radius(0px);
      @include border-top-radius(5px);
      @include border-bottom-radius(5px);
    }
  }
}
.img-right{
  .content{
    @include border-left-radius(5px);

    @include breakpoint(mobile){
      @include border-left-radius(0px);
      @include border-top-radius(5px);
    }
  }
 img{
    @include border-right-radius(5px);

   @include breakpoint(mobile){
     @include border-right-radius(0px);
     @include border-top-radius(5px);
   }
  }

  &:last-of-type{
    margin-bottom:0;
  }
}

.board-member{
  margin-bottom:75px;
  padding:0px 15px;

  @include breakpoint(mobile){
    margin-bottom:15px;
  }

  .col-sm-6{
    padding:0;

    @include breakpoint(mobile){
      background-color: #F3F4F4;
    }
  }


  .image {
    @include breakpoint(tablet){
      display:inline;
    }

    @include breakpoint(mobile){
      display: inline-block;
    }
    img {
      float: left;
      height: 100%;
      object-fit: cover;

      @include breakpoint(mobile){
        height: auto;
      }
    }
  }

  //figure{
  //  width:100%;
  //  height:100%;
  //  margin:0;


    img{
      height: 100%;
      object-fit: cover;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);

      @include breakpoint(mobile){
        height:auto;
      }
    }


  .content{
    background-color:#F3F4F4;
    height:100%;
    padding:41px 36px 49px 36px;

    @include breakpoint(mobile){
      padding:25px !important;
    }

    h5{
      color:#C64231;
      line-height:17px;
      margin-bottom:6px;
      text-transform:uppercase;
      font-family:$base-font-family;
    }

    h3{
      line-height:29px;
    }

    p{
      &:last-of-type{
        margin-bottom:0;
      }
    }
  }

    .news-content{
      background-color:#FFFFFF;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);

      @include breakpoint(mobile){
        margin-top:-10px;
      }

      time{
        color:#C64231;
        text-transform:uppercase;
        font-size:14px;
      }

      h2{
        font-size:27px;
        margin-top:20px;
        margin-bottom:30px;
        line-height:32px;
      }

      p{
        margin-bottom:35px !important;
        line-height:28px;
      }

      .button{
        font-size:16px;
      }
    }
}
// styling team member row below board members
.teammembers{
  margin-top:55px;

  .col-sm-3 {
    .image {
      position: relative;

      @include breakpoint(mobile) {
        margin-bottom: 15px;
      }
    }

    &:last-of-type {
      .image {
        @include breakpoint(mobile) {
          margin-bottom: 0;
        }
      }
    }
  }

  .shadow-boxing {
    position: absolute;
    width: 100%;
    height:50%;
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 1));
    padding:0px 0px 0px 19px;

    p, h3 {
      position: absolute;
      color: #FFF;
    }

    p {
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      bottom:50px;
      margin:0;

      @include breakpoint(mobile){
        font-size:18px;
      }
    }

    h3 {
      font-size: 21px;
      font-weight: $weight-bold;
      bottom:20px;

      @include breakpoint(tablet-landscape){
        font-size:18px;
      }

      @include breakpoint(tablet){
        bottom:10px;
        font-size:16px;
      }

      @include breakpoint(mobile){
        font-size:30px;
      }
    }
  }
}

// contact form styling
.contact-form{
  h2{
    margin-bottom:21px;
  }
  p{
    margin-bottom:30px;
    line-height:28px;
    margin-top:0;
  }

  .form-title{
    border:none;
    line-height:28px;
    margin-bottom:10px;
    padding:0;
  }
}

//form styling
.input-group{
  label{
    display:none;
  }

  input, textarea, select{
    @include border-radius(5px);
    border: 1.5px solid #CACACA;
    padding: 14px 16px;

    &::placeholder {
      color:rgba(140, 149, 153, 0.5);
      line-height:28px;
    }
  }

  select
  {
    color: #ccc;
  }
  option
  {
    color: #000;
  }
  option:first-child
  {
    color: #ccc;
  }

  textarea{
    height:315px;
  }
}

._detail-course{
  padding-top:90px;
  padding-bottom:80px;
  @include breakpoint(mobile){
    padding-top:20px;
    padding-bottom:50px;
  }

  time{
    background-color: #C64231;
    padding: 2px 7px;
    font-size: 16px;
    font-weight: 600;
    color: #F2F3F0;
  }

  img{
    box-shadow:10px 10px 0px 0px #C64231;

    @include breakpoint(mobile){
      margin-top:30px;
    }
  }

  h1{
    margin-top:15px;
  }
}

body.sitekick{
  header.main-navigation{
    top:70px;
  }
}

.p-b-0{
  padding-bottom:0;
}