// Colors
$link-color-default:		#8C9599;
$link-color-hover:			#0B2B3A;
$link-color-active:			#8C9599;
$link-color-visited:		#8C9599;

// Decoration
$link-decoration-default:	underline;
$link-decoration-hover:		none;
$link-decoration-active: 	none;
$link-decoration-visited: 	none;
